<template>
  <router-link :to="link" v-if="officialPartner" style="text-decoration: none">
    <div class="card--listing">
      <div class="top--part">
        <card-listing-image
          :linkDetail="link"
          :photos="officialPartner.photos"
          :primaryPhoto="{ url: officialPartner.photos[0] }"
          :allowMultiImage="allowMultiImage"
          :imageAlt="officialPartner.name"
        />
      </div>
      <div class="bottom--part">
        <h3 class="name multiline-ellipsis-2">
          {{ officialPartner.name }}
        </h3>
        <div class="name multiline-ellipsis-2">
          {{ officialPartner.region }}
        </div>
        <div>
          <div class="price--after">
            {{ priceLabel }}
          </div>
        </div>
        <div class="label--container">
          <div class="label mr-2">
            <span v-if="officialPartner.listing_type === 'R'">{{ $t('general.R') }}</span>
            <span v-else-if="officialPartner.listing_type === 'S'">{{ $t('general.S') }}</span>
          </div>
          <div class="label" v-if="officialPartner.property_type">
            {{ officialPartner.property_type }}
          </div>
        </div>
        <div class="developer--buffer" v-if="officialPartner.partner_name"></div>
      </div>
      <div class="project--partner-developer" v-if="officialPartner.partner_name">
        <!-- ini bagian developer name -->
        <div class="developer--name">{{ officialPartner.partner_name }}</div>
        <img :src="officialPartner.partner_logo_photo" :alt="officialPartner.partner_name" />
      </div>
    </div>
  </router-link>
</template>

<script>
import CardListingImage from '@/components/utils/partials/card-listing-image';
import HelperMixin from '@/mixins/helpers';
export default {
  mixins: [HelperMixin],
  components: {
    CardListingImage,
  },
  props: {
    officialPartner: {
      default: null,
      require: true,
    },
    allowMultiImage: {
      default: false,
    },
  },
  computed: {
    link() {
      return '/' + this.officialPartner.url;
    },
    priceLabel() {
      let minPrice = this.officialPartner.min_price;
      let maxPrice = this.officialPartner.max_price;
      let price = '';
      if (minPrice != null && minPrice.toLowerCase() !== 'rp 0 ribu') {
        price += minPrice;
      }
      if (maxPrice != null && maxPrice.toLowerCase() !== 'rp 0 ribu') {
        price += ' - ' + maxPrice;
      }
      if (
        this.officialPartner.listing_type === 'R' &&
        this.officialPartner.price_range_rent_period
      ) {
        price += ' per ' + this.officialPartner.price_range_rent_period;
      }
      return price;
    },
  },
  filters: {
    lowerCase(e) {
      return e.toLowerCase();
    },
  },
};
</script>
